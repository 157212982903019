<template>
  <div class="footer">
    <p>以上内容由网络爬虫自动抓取，以非人工方式自动生成。知视搜索不储存、复制、传播任何视频文件。  </p>
    <p><a target="_blank" href="/policy">免责声明</a></p>
  </div>
</template>
  
<script>
  import { debounce } from '@/utils/index'


  export default {
    name: 'Footer',
    props: {
      styles: Object,
      size: String,
      focus: Number
    },
    debounceFunc: null,
    inputPreText: '',
    data(){
      return {
        text: "",
      }
    },
    mounted(){
     
    },
    methods: {
     
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang='less'>
.footer {
  text-align: center;
  color: #AAA;
  font-size: 12px;
  margin: 400px auto 20px;
}

a {
  color: #666;
}
  </style>
  