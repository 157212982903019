<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { storage } from "@/utils";

export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {},
};
</script>
<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?eadc4d220f195d1c3138de6297d95c6f";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>
<style>
a {
  color: #666;
}
.outstand {
  color: #ed1b25 !important;
}
</style>