
import Policy from './views/Policy.vue'
import Search from './views/Search.vue'
// import Video from './views/Video.vue'

const Video = () => import('./views/Video.vue')

export default  [
   
    { 
        path: '/video/:id', 
        component: Video ,
        name: "Video",
        props: (route) => ({ 
            videoID: route.params.id, 
    })},
    { 
        name: "Polce",
        path: '/policy', 
        component: Policy 
    },
    { 
        path: '/', 
        alias: "/",
        component: Search,
        name:"Search",
        props: (route) => ({ 
            query: route.query.w, 
        }),
    }
  
]