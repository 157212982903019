
const mappp = {
  'bilibili.com' : "哔哩哔哩",
  'qq.com': "腾讯视频"
}
export default {
  get(url) {
    if (!url) return url
    for (let k in mappp) {
      if (url.indexOf(k) >= 0) {
        return mappp[k]
      }
    }
   
  }
}