<template>
  <div class="sidebar">
    <ul>
      <li v-for="item in recommend" v-bind:key="item.name">
        <a class="item" :href="'/?w=' + item.value">{{item.name}}</a>
      </li>
      
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      recommend: [
         {
          name:"综艺/脱口秀/罗永浩",
          value:"还债"
        },
        {
          name:"心理学/武志红",
          value:"武志红"
        },
         {
          name:"vlog/心理学/雪颖Shae",
          value:"雪颖Shae"
        },
         {
          name:"教育/法律/罗翔",
          value:"罗翔"
        },
         {
          name:"经济学/鹤老师说经济",
          value:"鹤老师说经济"
        },
        {
          name:"科普/回形针PaperClip",
          value:"回形针PaperClip"
        },
         {
          name:"社科人文/百大/硬核的半佛仙人",
          value:"硬核的半佛仙人"
        },
      ],
    };
  },
  mounted(){
    let recommend = this.recommend
    for(let item of recommend) {
      let names = item.name.split("/")
      names = names.join(" / ")
      item.name = names
    }
  },
  methods: {
    
    handleClick(e) {
      this.$emit("change", e.key)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='less'>
.sidebar {
  width: 200px;
  position: absolute;
  left: 0;
  top: 100px;
}
.sidebar ul,.sidebar li {
  margin: 0;
  padding: 0;
}
.sidebar li .item {
  padding: 10px 20px;
  background: #FEFEFE;
  border-bottom: 1px solid #EEE;
  display: inline-block;
}

.sidebar li .item {
  color: #ed1b25 !important;
  width: 100%;
}
.sidebar li .item:hover {
  background: #f3eded
}

@media screen and (max-width: 600px) {
  .sidebar {
    display: none;
  }
}
</style>
