<template>
  <div class="container">
    
  <h1>免责声明</h1>
<p>在使用知视搜索引擎（以下简称知视搜索）前，请您务必仔细阅读并透彻理解本声明。您可以自愿选择是否使用知视搜索，但如果您使用知视搜索，您的使用行为将被视为对本声明全部内容的认可。</p>


<p>1.知视搜索服务：本服务可向您提供与其他国际互联网上之网站或资源之链接。但是，您了解并明确知悉知视搜索无法控制这些网站及资源，因您使用或依赖任何由这些网站或资源发布的或经由这些网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，我们无法对此承担任何直接或间接责任。</p>


<p>2.鉴于知视搜索以非人工检索方式、根据您输入的关键字自动生成到第三方网页的链接，以便用户能够找到和使用第三方网站上各种文档、图片及其他所需内容。知视搜索本身不储存、复制、传播、控制编辑或修改被链接的第三方网站上的信息内容或其表现形式。对其概不负责，亦不承担任何法律责任。</p>


<p>3.任何通过使用知视搜索而搜索链接到的第三方网站资源均系他人制作或提供，您可能从该第三方网站上获得资源及享用服务，知视搜索对其合法性概不负责，亦不承担任何法律责任。</p>


<p>4.知视搜索搜索结果根据您键入的关键字自动搜索获得并生成，不代表知视搜索赞成被搜索链接到的第三方网站上的内容或立场。</p>


<p>5.您应该对使用知视搜索的结果自行承担风险。知视搜索不做任何形式的保证：不保证搜索结果满足您的要求，不保证搜索服务不中断，不保证搜索结果的安全性、正确性、及时性、合法性，不保证由于您使用服务而获得的信息将是准确的或可靠的；因网络状况、通讯线路、第三方网站等任何原因而导致您不能正常使用知视搜索，知视搜索不承担任何法律责任。</p>


<p>6.知视搜索高度重视知识产权保护，若知视搜索收录的第三方网页内容无意侵犯了您的权益，请提供相关有效书面证明发往 report@zhihu.video，我们核实后单方面进行删改页面。详情请参见《版权说明》。</p>


<p>7.知视搜索尊重并保护您的个人隐私权，知视搜索无注册会员、留言、评论、交易等交互功能，因此您的用户名、电子邮件地址、QQ号、微信号、证件号等一切个人资料不会被知视搜索所记录或储存，更不存在主动地泄露或以任何形式向任何第三方提供您个人资料的行为。但是，您了解并同意：您在使用搜索引擎时输入的关键字将不被认为是您的个人隐私资料。</p>


<p>8.知视搜索是个非经营性个人网站，旨在方便用户查找学习资料，知视搜索坚决打击利用网盘及其搜索服务侵犯他人个人隐私和盗版等违法行为，本站对一些非法恶意的搜索进行了屏蔽，屏蔽的词库正在不断的扩大完善中，如您发现有任何不良信息，欢迎举报反馈给我们，举报邮箱: report@zhihu.video，我们将尽快处理。</p>
  </div>
</template>

<script>

import vueQr from 'vue-qr'
export default {
  name: "Policy",
    components: {},
    props:{
     
    },
    
    mounted(){
      
    },
    data() {
        return {
           
        }
    }
  }
</script>

<style scoped >
.container {
  padding: 1px;
  max-width: 1000px;
  padding: 10px;
  margin: 0 auto;
}

</style>