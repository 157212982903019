<template>
  <a-layout style="background: #fff">
    <div class="container">
      <div class="logo-text">知视<span class="logo-bg">搜索</span></div>
      <div class="input-wrap">
        <input
          v-model="searchValue"
          :placeholder="preSearchValue"
          @keyup.enter="handleSearch"
        /><button @click="handleSearch">搜索</button>
      </div>
      <div class="search-tags">
        <a
          class="tag"
          :key="k.name"
          v-for="k in lastedSearch"
          @click="handleSetSearchValue(k.value)"
        >
          {{ k.name }}
        </a>
      </div>
      <div class="top-panel">
        <p class="lasted-update">
          <a target="_blank" :href="'/video/' + lastedUpdate.id">{{
            lastedUpdate.title
          }}</a>
          <img
            src="../assets/new.png"
            style="height: 40px; vertical-align: middle; margin: 0 4px"
          />
        </p>
        <div v-show="searching" style="text-align: center">拼命加载中 ...</div>
      </div>

      <div class="search-result">
        <ul>
          <li v-bind:key="item.id" v-for="item in searchResult">
            <div
              class="result-cover"
              :style="{ backgroundImage: `url(${item.cover})` }"
            ></div>
            <div class="result-info">
              <div class="result-title">
                <a target="_blank" :href="'/video/' + item.video_id">{{
                  item.title
                }}</a>
              </div>
              <div class="result-timestamp">
                <a
                  target="_blank"
                  class="timestamp"
                  :href="item.source_url + '&start_time=' + item.start_time"
                  >{{ item.start_time }}</a
                >
              </div>
              <div class="result-content">
                <span v-html="item.content"></span>
              </div>
              <div class="result-tail">
                <a-tag
                  color="#da767b"
                  :key="cate"
                  v-for="cate in item.categories"
                  >{{ cate }}</a-tag
                >
              </div>
            </div>
          </li>
        </ul>
        <div class="pagination" v-if="searchResult.length > 0">
          <a-pagination
            v-model="current"
            :total="total"
            @change="handleNextPage"
          />
        </div>
      </div>
    </div>
    <Sidebar></Sidebar>
    <Footer></Footer>
  </a-layout>
</template>

<script>
import Sidebar from '@components/Sidebar'
import Footer from "@components/Footer";

const excerptFunc = (text, target) => {
  target = target.split(" ")[0];
  const targetIndex = text.indexOf(target);
  if (targetIndex < 0) {
    if (text.length > 50) {
      return text.substr(0, 50) + "...";
    }
    return text;
  }
  return (
    (targetIndex - 20 > 0 ? "..." : "") +
    text.substr(targetIndex - 20, targetIndex) +
    text.substr(targetIndex, 20) +
    (targetIndex + 20 < text.length ? "..." : "")
  );
};

export default {
  name: "Search",
  props: {
    query: String,
  },
  components: {
    Footer,
    Sidebar,
  },
  data() {
    return {
      lastedUpdate: {},
      searching: false,
      defaultCover:
        "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2999705736,2629890130&fm=26&gp=0.jpg",
      searchValue: "",
      preSearchValue: "",
      searchResult: [],
      lastedSearch: [],
      current: 1,
      total: null,
    };
  },
  mounted: function () {
    this.loadSearchTags();
    this.loadLastedVideo();
    this.handleSetSearchValue(this.query);
  },
  watch: {},
  computed: {},
  methods: {
    loadLastedVideo: function () {
      this.$http
        .get("/api/v1/videos", {
          offset: 0,
          limit: 1,
        })
        .catch((e) => {
          this.loading = false;
          this.$error({
            title: "网络错误",
            content: e,
          });
        })
        .then((resp) => {
          this.loading = false;
          this.lastedUpdate = resp.data.data.list[0];
        });
    },
    loadSearchTags: function () {
      this.$http
        .get("/api/v1/search/lasted")
        .catch((e) => {
          this.loading = false;
          this.$error({
            title: "网络错误",
            content: e,
          });
        })
        .then((resp) => {
          this.loading = false;
          this.lastedSearch = resp.data.data;
        });
    },
    handleSetSearchValue: function (v) {
      this.searchValue = v;
      this.search(v, 1);
    },
    search: function (word, pageNo) {
      this.preSearchValue = this.searchValue;
      if (word) {
        this.searchValue = word;
      }
      if (pageNo) {
        this.current = pageNo;
      }
      if (!this.searchValue) return;
      this.searching = true;
      this.$http
        .get("/api/v1/search/speech", {
          w: this.searchValue,
          offset: (this.current - 1) * 10,
          limit: 10,
        })
        .catch((e) => {
          this.searching = false;
          this.$error({
            title: "网络错误",
            content: e,
          });
        })
        .then((resp) => {
          this.searching = false;
          let results = resp.data.data.list;
          for (let i = 0; i < results.length; i++) {
            results[i].content = excerptFunc(
              results[i].content,
              this.searchValue
            );
            const replaceTarget = this.searchValue.split(" ")[0];
            results[i].content = results[i].content.replaceAll(
              replaceTarget,
              `<span class='outstand'>${replaceTarget}</span>`
            );
            results[i].categories = results[i].category.split("/");
          }
          this.searchResult = results;
          this.total = resp.data.data.count;
          console.log(resp);
        });
    },
    handleNextPage: function () {
      this.search(this.searchValue, this.current);
    },
    handleSearch: function (e) {
      if (!this.searchValue) return;
      this.search(this.searchValue, 1);
    },
  },
};
</script>

<style scoped lang="less">
.container {
  width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
}
.logo-text {
  color: #333;
  font-size: 30px;
  text-align: center;
  padding: 60px 0 40px 0;
}
.logo-bg {
  color: #fff;
  border-radius: 8px;

  background: #ED1B25;
  padding: 4px;
  margin-left: 4px;
}

::-webkit-input-placeholder {
  color: #aaa;
}

.input-wrap {
  display: flex;
  width: 500px;
  margin: 0 auto;
}
.input-wrap input {
  flex: 1;
  color: #333;
  height: 40px;
  border-radius: 8px 0 0 8px;
  border-width: 2px 0 2px 2px;
  outline: none;
  padding: 0 20px;
}

.input-wrap button:hover {
  cursor: pointer;
  background: #000;
}
.input-wrap button {
  height: 40px;
  border-radius: 0 8px 8px 0;
  border-width: 2px 2px 2px 0;
  background: #333;
  color: #fff;
  padding: 0 20px;
  outline: none;
}
.lasted-update {
  margin: 10px auto;
  text-align: center;
}
.lasted-update a {
  color: #999;
}
.search-tags {
  width: 500px;
  margin: 0 auto;
  margin-top: 20px;
}

.search-result {
  max-width: 1000px;
  margin: 20px auto;
}
.search-result ul {
  margin: 0;
  padding: 0;
}
.search-result li {
  list-style: none;
  display: flex;
  flex-direction: row;
  transition: 500ms;
  margin-top: 20px;
  font-size: 16px;
}
.search-result li:hover {
  /* box-shadow: 0px 0px 6px; */
}
.search-result .result-cover {
  width: 230px;
  height: 130px;
  margin: 10px;
  background-image: url(https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2999705736,2629890130&fm=26&gp=0.jpg);
  background-size: cover;
  background-position: center;
  background-color: #eee;
}

.search-result .result-info {
  flex: 1;
}

.search-result .result-title {
  font-size: 20px;
  padding: 10px 0;
}

.timestamp {
  color: #666;
}

.search-result .result-content {
}

.search-result .result-tail {
  margin-top: 10px;
}
.pagination {
  margin: 10px auto;
  text-align: center;
}

a {
  color: #333;
}

.tag {
  color: #cc3940;
  background: #f3eded;
  border-radius: 2px;
  text-decoration: none;
  padding: 4px 8px;
  margin: 2px 4px;
  display: inline-block;
}

.tag:hover {
  color: #f3eded;
  background: #cc3940;
}
.top-panel {
  height: 40px;
}

@media screen and (max-width: 600px) {
  .container {
    margin: 0 auto;
    width: auto;
    padding-top: 20px;
  }
  .logo-text {
    font-size: 40px;
    text-align: center;
    padding: 20px 0 10px 0;
  }
  .logo-bg {
    color: #fff;
    border-radius: 8px;
    background: #ed1b25;
    padding: 2px;
    margin-left: 4px;
  }

  .input-wrap {
    display: flex;
    margin: 0 auto;
    width: auto;
    padding: 0 1em;
    font-size: 18px;
  }
  .input-wrap input {
    flex: 1;
    color: #333;
    height: 2em;
    width: auto;
    border-radius: 8px 0 0 8px;
    border-width: 2px 0 2px 2px;
    outline: none;
    padding: 0 0.5em;
  }

  .input-wrap button:hover {
    cursor: pointer;
    background: #000;
  }
  .input-wrap button {
    height: 2em;
    border-radius: 0 8px 8px 0;
    border-width: 2px 2px 2px 0;
    background: #333;
    color: #fff;
    padding: 0 0.5em;
    outline: none;
  }

  .search-result {
    max-width: auto;
    margin: 20px auto;
    padding: 0 0.5em;
  }
  .search-result li {
    list-style: none;
    display: block;
    margin-top: 20px;
  }

  .search-result .result-cover {
    width: 100%;
    height: 100px;
    margin: 0;
    background-image: url(https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=2999705736,2629890130&fm=26&gp=0.jpg);
    background-size: cover;
    background-position: center;
  }
  .search-result .result-title {
    background: #333;
    padding: 0.5em;
    color: #fff;
  }
  .search-result .result-title a {
    /* background: rgba(0,0,0,0.5); */
    color: #fff;
    font-size: 0.8em;
  }

  .search-tags {
    width: auto;
    padding: 0.5em;
  }
}
</style>
